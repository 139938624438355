import { render, staticRenderFns } from "./Referred.vue?vue&type=template&id=d071c886&scoped=true"
import script from "./Referred.vue?vue&type=script&lang=js"
export * from "./Referred.vue?vue&type=script&lang=js"
import style0 from "./Referred.vue?vue&type=style&index=0&id=d071c886&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d071c886",
  null
  
)

export default component.exports