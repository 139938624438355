<template>
  <div class="columns is-multiline transactionDetail">
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard">
      Referidos
    </div>
    <div class="column is-12 separator"></div>
    <div class="column is-12" v-if="!loading">
      <div class="columns is-multiline is-mobile referred" v-for="referred in referreds" :key="referred.id">
        <div class="column is-3">
          <div class="media-left">
            <figure class="image is-64x64 thumbnailImage" :style="`background-image: url('${referred.imageUrl}')`">
            </figure>
          </div>
        </div>
        <div class="column is-8">
          <p class="tagElement">{{referred.username}}</p>
          <p class="dateReferred">{{formatDateTimestamp(referred.createdAt)}}</p>
        </div>
      </div>
    </div>
    <div class="column is-12 has-text-centered is-empty" v-else>
      Obteniendo información...
    </div>
    <div class="column is-12 has-text-centered is-empty" v-if="!loading && referreds.length === 0">
      Este usuario no ha referido usuarios
    </div>
  </div>
</template>

<script>
import mixin from '../../mixin/validateDate'
export default {
  name: 'CustomerRefered',
  mixins: [mixin],
  props: {
    referreds: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .top20 {
    margin-top: 10px;
  }
  .tagElement {
    font-weight: bold;
    color: var(--secondary-color);
    font-size: 14px;
    padding-top: 10px;
  }
  .valueElement {
    font-size: 12px;
    font-weight: 300;
    padding-top: 5px;
  }
  .transactionDetail .is-6 {
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .thumbnailImage {
    background-size: cover;
    background-position: center;
    border-radius: 45px;
  }
  .dateReferred {
    font-size: 12px;
  }
  .referred {
    margin: 0px;
    border-bottom: 1px solid #F0F0F0;
  }
  .is-empty {
    font-size: 12px;
  }
</style>
